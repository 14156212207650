<!--
File: UserRoles.vue
Description: show list of roles assigned to users.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <md-input type="search" class="mb-3" clearable style="width: 200px"
              :placeholder="$t('label.search_records')" v-model="searchQuery" />
          </md-field>
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-success" @click="addUserRole"> {{ $t('buttons.add') }} </md-button>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <md-progress-spinner v-show="showSpinner" :md-diameter="100" :md-stroke="10" md-mode="indeterminate" />
      <md-table :value="searchedData" @md-selected.native="onSelect" :md-sort.sync="currentSort" md-fixed-header
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort"
        class="paginated-table table-striped table-hover">
        <md-table-empty-state :md-label="$t('label.no_data')"
          :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}">
          <md-table-cell :md-label="$t('stdCols.name')" md-sort-by="user_full_name">
            {{ item.user_full_name }}
          </md-table-cell>
          <md-table-cell :md-label="$t('users.email')" md-sort-by="email">
            {{ item.email }}
          </md-table-cell>
          <md-table-cell :md-label="$t('users.role')" md-sort-by="role_key">
            {{ item.role_key }}
          </md-table-cell>
          <md-table-cell :md-label="$t('stdCols.description')" md-sort-by="role_description">
            {{ item.role_description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('tables.actions')">
            <div>
              <md-button v-if="amIAdmin" class="md-just-icon md-danger md-simple" :title="$t('buttons.delete')"
                @click.stop.prevent="handleDelete(item)">
                <md-icon>delete</md-icon>
              </md-button>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </md-card-content>

    <md-card-actions md-alignment="space-between">
      <div class>
        <p class="card-category">
          {{ $t('label.showing_from_to_of_entries', { from: to > 0 ? from + 1 : 0, to: to, total }) }}
        </p>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label for="pages">{{ $t('label.per_page') }}</label>
          <md-select v-model="pagination.perPage" name="pages">
            <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
              {{ item }}
            </md-option>
          </md-select>
        </md-field>
      </div>
      <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
        :per-page="pagination.perPage" :total="total"></pagination>
    </md-card-actions>
  </md-card>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { Pagination } from '@/components'
import { customSortMixin } from '@/mixins/customSortMixin'
import messagesMixin from '@/mixins/messagesMixin'
import Fuse from 'fuse.js'

export default {
  name: 'user-roles-list',
  mixins: [customSortMixin, messagesMixin],

  data() {
    return {
      showSpinner: true,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['user_full_name', 'email', 'role_key', 'role_description'],
      currentSort: 'user_full_name',
      currentSortOrder: 'asc',
      fuseSearch: null,
    }
  },

  components: {
    Pagination
  },

  mounted() {
    this.reloadData()
  },

  methods: {
    toggleSpinner(state) {
      this.showSpinner = state
    },

    async reloadData() {
      this.toggleSpinner(true)
      await this.$store.dispatch('LOAD_USER_ROLES', {})
      await this.$store.dispatch('GET_ROLES_DROPDOWN', false)
      await this.$store.dispatch('LOAD_USERS_LIST')
      this.fuseSearch = new Fuse(this.userRolesList, { keys: this.propsToSearch, threshold: 0.3 })
      this.toggleSpinner(false)
    },

    async addUserRole() {
      const { value } = await this.doubledDropdownInput(
        this.$t('label.add_user_roles'),
        this.$t('label.select_user'), 
        this.usrList, 
        this.$t('label.select_role'), 
        this.rolesList
      );
      if (!value || value == []) { //User cancelled input
        return
      }
      this.toggleSpinner(true)
      const newRole = {
        "fk_user": value[0],
        "fk_role": value[1]
      }
      console.log('newRole', newRole)

      let errDesc = ''
      try {
        await this.$store.dispatch('ADD_USER_ROLE', newRole);
      this.reloadData()
      } catch (err) {
        errDesc = err.message
      }
      this.toggleSpinner(false)
      this.savedMessage(errDesc, 's', 'a')
    },

    async handleDelete(item) {
      const result = await this.deleteConfirmation(`${this.$t('users.role')} ${item.role_key}`)
      if (!result.value) { return }

      this.toggleSpinner(true)
      this.$store.dispatch('DEL_USER_ROLE', item.user_role_id).then(
        () => {
          this.reloadData()
          this.deletedMessage(item.role_key)
        },
        (err) => {
          this.errorMessage(err.message)
        }
      )
      this.toggleSpinner(false)
    }
  },

  computed: {
    ...mapState({
      userRolesList: (state) => state.RBAC.userRolesList,
      rolesList: (state) => state.Dropdowns.roles,
      usersList: (state) => state.Users.list,
    }),
    ...mapGetters(['amIAdmin']),

    /*async rolesList() {
      const res = await this.$store.dispatch('LOAD_ROLES_LIST', true)
      return res.map(item => ({
        id: item.role_id,
        description: item.role_key
      })).sort((a, b) => a.description.localeCompare(b.description, undefined, { sensitivity: 'base' }))
    },*/

    usrList() {
      return this.usersList.map(item => ({
        id: item.id,
        description: `${item.first_name} ${item.last_name}`
      })).sort((a, b) => a.description.localeCompare(b.description, undefined, { sensitivity: 'base' }))
    },

    userRolesCount() {
      return this.userRolesList.length
    },

    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },

    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },

    total() {
      return this.searchQuery ? this.searchedData.length : this.userRolesCount
    },

    searchedData() {
      if (!this.userRolesCount) {
        return []
      } else {
        return (!this.searchQuery) ? this.userRolesList : this.fuseSearch.search(this.searchQuery)
      }
    }
  },

  watch: {
  }
}
</script>
<style lang="scss" scoped>
.cell-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .md-button {
    margin: 3px 0;
    min-width: 100px;
  }
}

.md-card {
  margin: 0px 0;
}

.md-table-head-label {
  padding-right: 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-button+.md-button {
  margin-left: 10px;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}
</style>